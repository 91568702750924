<template>
  <div class="page-customer">
    <content-header :title="title" :breadList="['系统设置', '角色管理']">
      <el-button
        slot="tools"
        type="primary"
        round
        @click="
          existDrawer = true;
          editType = 'add';
          curRole = {};
        "
      >
        <i class="el-icon-plus"></i>
        <span>新增角色</span>
      </el-button>
    </content-header>
    <div class="content-wrapper" v-loading="loading">
      <el-tree
        :data="tableData"
        node-key="_id"
        default-expand-all
        :expand-on-click-node="false"
        :render-content="renderContent"
        icon-class="el-icon-arrow-right"
        class="role-tree"
        :props="{
          children: 'childrens',
        }"
      >
      </el-tree>
    </div>
    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="curRole"
      @success="handleSaved"
    ></edit-drawer>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该角色吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="confirmDelete" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ContentHeader from "@/views/layout/content-header";
import EditDrawer from "./components/edit-drawer";
import { apiGetRolesList, apiDeleteRole } from "@/api/auth";

export default {
  components: {
    EditDrawer,
    ContentHeader,
  },
  data() {
    return {
      title: this.$route.meta.title,
      loading: false,
      existDrawer: false,
      delConfirmShow: false,
      editType: "add",
      curRole: {},
      tableData: [],
    };
  },
  methods: {
    getRoleList() {
      this.loading = true;
      const param = {
        type: 1,
      };
      apiGetRolesList(param)
        .then((res) => {
          let list = res.roles;
          list.forEach((element) => {
            element.label = element.Name;
          });
          this.tableData = list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 添加子角色
    handleAdd(detail, node) {
      detail.parentName = node.data.Name;
      this.curRole = detail;
      this.editType = "add";
      this.existDrawer = true;
    },
    // 编辑
    handleEdit(detail, node) {
      detail.parentName = node.parent.data.Name;
      this.curRole = detail;
      this.editType = "edit";
      this.existDrawer = true;
    },
    confirmDelete() {
      apiDeleteRole(this.curRole._id).then(() => {
        this.delConfirmShow = false;
        this.getRoleList();
      });
    },
    handleSaved() {
      this.existDrawer = false;
      this.getRoleList();
    },
    handleDelete(detail) {
      this.curRole = detail;
      this.delConfirmShow = true;
    },
    renderContent(h, { data, node }) {
      return h("div", [
        h("div", [
          h(
            "span",
            {
              attrs: {
                class: "node-label",
              },
            },
            data.Name
          ),
          h("i", {
            attrs: {
              class: "iconfont icon-edit",
            },
            on: {
              click: () => {
                this.handleEdit(data, node);
              },
            },
          }),
          h("i", {
            attrs: {
              class: "iconfont icon-delete",
            },
            on: {
              click: () => {
                this.handleDelete(data);
              },
            },
          }),
        ]),
        h("div", [
          h(
            "span",
            {
              attrs: {
                class: "btn-add",
              },
              on: {
                click: () => {
                  this.handleAdd(data, node);
                },
              },
            },
            "+ 添加角色"
          ),
        ]),
      ]);
    },
  },
  mounted() {
    this.getRoleList();
    if (this.$route.query.status === "add") {
      this.existDrawer = true;
      this.editType = "add";
    }
  },
};
</script>

<style lang="less" scoped>
.page-customer {
  .tool-wrap {
    /deep/ i {
      font-size: 20px;
      margin-right: 20px;
      color: #9a9a9e;
    }
  }
  .role-tree {
    /deep/.el-tree-node__content {
      // position: relative;
      height: auto;
      align-items: flex-start;
      // padding-left: 26px !important;
      .expanded {
        // position: absolute;
        padding: 4px;
        margin-right: 6px;
        // top: 0;
        // left: 0;
        font-weight: bold;
        color: #747378;
      }
    }
    /deep/.el-tree-node__children {
      padding-left: 26px;
    }
    /deep/.node-label {
      font-size: 14px;
      line-height: 20px;
      color: #26262c;
      margin-right: 58px;
    }
    /deep/.iconfont {
      margin-right: 24px;
    }
    /deep/.btn-add {
      display: block;
      margin: 18px 0 24px 26px;
      font-size: 12px;
      font-weight: 500;
      line-height: 19px;
      color: #0483ec;
      cursor: pointer;
    }
  }
}
</style>
