<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{ saveType === "add" ? "新增角色" : "修改角色" }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button type="primary" round @click="handleSave" :loading="saving"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="80px"
        >
          <cond-card>
            <el-form-item label="角色名称" prop="Name">
              <el-input
                v-model="formData.Name"
                placeholder="请输入角色名称"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item label="父角色" v-if="detail.parentName">
              <div>{{ detail.parentName }}</div>
            </el-form-item>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { apiCreateRole, apiEditRole } from "@/api/auth";
import condCard from "@/components/cond-card";
export default {
  components: {
    condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        Name: "",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入角色名称",
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      channelLabelMap: "common/channelLabelMap",
    }),
  },
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          this.getRoleDetail();
        });
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      this.saving = true;
      const param = {
        ...this.formData,
      };
      let prom;
      if (this.saveType === "add") {
        param.ParentRoleId = (this.detail && this.detail._id) || 0;
        prom = apiCreateRole(param);
      } else {
        prom = apiEditRole(this.detail._id, param);
      }
      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    getRoleDetail() {
      if (this.saveType === "edit") {
        this.formData.Name = this.detail.Name;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }
}
</style>
